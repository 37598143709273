import { selectedLanguageSelector } from "app/rkt_query/SettingsReducer";
import { useSelector } from "react-redux";

/**
 * also translate text smartly if its a translation
 * @param {a} param0 
 * @returns 
 */
export const DZText = ({ children, isTitle = false, maxChars = null }) => {

    const selectedLanguage = useSelector(selectedLanguageSelector);

    let tText = children?.en === undefined ? children : children?.[selectedLanguage] ?? '';
    // tText = isTitle? toTitleCase(tText) : tText
    tText = maxChars ? tText?.substring(0, maxChars) + '...' : tText

    return tText ? tText : ''
}